<template>
  <Section class="bg-white">
    <div class="md:grid md:grid-cols-3 gap-x-10 gap-y-4">
      <div class="md:mt-3">
        <div class="h-1 w-full bg-black mb-4 hidden lg:block"></div>
        <h3 class="text-xl md:text-2xl lg:text-3xl font-semibold mb-6">
          {{ feedback.title }}
        </h3>
      </div>
      <MDC
        :value="feedback.content"
        tag="article"
        class="col-span-2 text-lg lg:text-xl"
      />
    </div>
    <div class="w-full text-center mt-4">
      <UButton
        :to="feedback.link"
        variant="outline"
        size="lg"
        target="_blank"
      >
        {{ feedback.action }}
      </UButton>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const feedback = toRef(props.data)
</script>

<template>
  <Section class="bg-neutral-150">
    <template #header>{{ principles.title }}</template>
    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-6 lg:gap-y-10">
      <article
        v-for="(item, index) in principles.items"
        :key="index"
        class="flex flex-col gap-y-4 h-full"
      >
        <h3 class="text-xl md:text-2xl lg:text-3xl font-semibold">
          {{ item.title }}
        </h3>
        <p class="text-base lg:text-lg">
          {{ item.content }}
        </p>
      </article>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const principles = toRef(props.data)
</script>

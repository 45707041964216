<template>
  <Section class="bg-white">
    <template #header>{{ about.title }}</template>
    <div class="md:px-32 xl:px-56">
      <div class="text-base md:text-lg lg:text-xl">
        <MDC
          :value="about.content"
          tag="article"
          class="text-lg lg:text-xl prose"
        />
      </div>
      <nav class="mt-6">
        <NuxtLink
          :to="'/about'"
          class="inline-flex items-center gap-x-3 font-semibold text-base md:text-lg lg:text-xl text-primary-500 hover:text-primary-300"
        >
          {{ about.action }}
          <NuxtIcon name="arrow-right" />
        </NuxtLink>
      </nav>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const about = toRef(props.data)
</script>

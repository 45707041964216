<template>
  <Section class="bg-neutral-150">
    <template #header>{{ faq.title }}</template>
    <div class="flex flex-col space-y-1 md:px-32 xl:px-56">
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="bg-white"
      >
        <button
          class="flex items-center justify-between w-full py-6 px-7 group"
          :class="{ active: question.isExpanded }"
          @click="() => handleAccordion(index as number)"
        >
          <span class="font-semibold text-xl lg:2xl text-left">
            {{ question.title }}
          </span>
          <span class="
              flex items-center justify-center rounded-full w-9 h-9 text-primary
              group-hover:bg-primary-300 group-hover:text-white transition duration-300
            ">
            <NuxtIcon name="plus" class="text-2xl group-[.active]:rotate-45 transition-transform duration-300" />
          </span>
        </button>
        <Collapse as="section" :when="question.isExpanded">
          <MDC
            :value="question.answer"
            tag="article"
            class="text-base lg:text-lg px-7 py-5 pt-0 prose"
          />
        </Collapse>
      </div>
    </div>
  </Section>
</template>

<script setup lang="ts">
  import { Collapse } from 'vue-collapsed'

  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const faq = toRef(props.data)

  const list = faq.value.questions

  const questions = reactive(
    list.map(({ title, answer }, index: number) => ({
      title,
      answer,
      isExpanded: false
    }))
  )

  const handleAccordion = (selectedIndex: number) => {
    questions.forEach((_: any, index: number) => {
      questions[index].isExpanded =
        index === selectedIndex ? !questions[index].isExpanded : false
    });
  }
</script>

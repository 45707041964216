<template>
  <Section class="bg-white">
    <div class="flex flex-col gap-y-10 md:gap-y-14 lg:gap-y-20">
      <h1 class="text-center text-4xl md:text-5xl lg:text-6xl text-primary-300 font-semibold uppercase">
        {{ hero.headline }}
      </h1>
      <article class="grid lg:grid-cols-2 gap-y-12 gap-x-12">
        <div class="flex flex-col justify-center">
          <div class="flex flex-col gap-y-12 items-start">
            <div>
              <MDC
                :value="hero.title"
                tag="header"
                class="text-3xl lg:text-4xl font-bold mb-6"
              />
              <div class="h-1 w-14 bg-black"></div>
            </div>
            <MDC
              :value="hero.subtitle"
              tag="article"
              class="text-lg lg:text-xl prose"
            />
            <UButton
              :to="hero.link"
              target="_blank"
              size="xl"
            >
              {{ hero.action }}
            </UButton>
          </div>
        </div>
        <div class="flex justify-center lg:justify-end">
          <div class="aspect-[2/3] max-h-[36rem] lg:max-h-[45rem]">
            <img
              :src="hero.image"
              class="w-full h-full object-cover"
              :alt="hero.headline"
            >
          </div>
        </div>
      </article>
      <article class="py-5 border-t-4 border-b-4 border-gray-700">
        <p class="text-center text-xl lg:text-3xl font-bold">
          {{ hero.slogan }}
        </p>
      </article>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const hero =  toRef(props.data)
</script>

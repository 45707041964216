<template>
  <main
    v-if="home"
    class="mt-20"
  >
    <Hero v-if="home.hero" id="hero" :data="home.hero" />
    <Features v-if="home.features" id="features" :data="home.features" />
    <About v-if="home.about" :data="home.about" />
    <Principles v-if="home.principles" id="principles" :data="home.principles" />
    <Feedback v-if="home.feedback" id="feedback" :data="home.feedback" />
    <Price v-if="home.prices" id="prices" :data="home.prices" />
    <FAQ v-if="home.faq" id="faq" :data="home.faq" />
    <Articles v-if="home.articles" id="articles" :data="home.articles" />
    <Contacts v-if="home.contacts" id="contacts" :data="home.contacts" />
  </main>
</template>

<script setup lang="ts">
  const { data } = await useAsyncData(() => queryContent('home/').find())
  const home: Record<string, any> = {}

  data.value?.forEach(item => {
    const path = item._file
    const fileName = path?.substring(path.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')
    if (fileName) {
      home[fileName] = item
    }
  })
</script>

<template>
  <section class="py-10 md:py-14 lg:py-20 scroll-mt-20">
    <div class="container">
      <h2 v-if="slots.header" class="text-3xl md:text-4xl lg:text-4.5xl font-semibold text-center mb-10 md:mb-14 lg:mb-20">
        <slot name="header"></slot>
      </h2>
      <slot></slot>
    </div>
  </section>
</template>

<script setup lang="ts">
  const slots = useSlots()
</script>

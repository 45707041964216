<template>
  <Section class="bg-white">
    <template #header>{{ articles.title }}</template>
    <div class="grid lg:grid-cols-3 gap-10">
      <article
        v-for="article in posts"
        :key="article._path"
        class="relative group"
      >
        <NuxtLink
          :to="article._path"
          class="absolute w-full h-full z-10"
        />
        <div class="h-full flex flex-col justify-between bg-neutral-150 rounded shadow group-hover:drop-shadow-xl px-8 py-6">
          <div class="mb-8">
            <h3 class="text-lg lg:text-2xl font-semibold mb-6">
              {{ article.title }}
            </h3>
            <p class="text-base">
              {{ article.description }}
            </p>
          </div>
          <div class="flex items-center gap-x-2">
            <span class="text-primary">{{ articles.action }}</span>
            <NuxtIcon name="arrow-right" class="text-primary text-sm" />
          </div>
        </div>
      </article>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const articles = toRef(props.data)

  const { data: posts } = await useAsyncData(
    () => queryContent('/posts')
      .sort({ date: -1 })
      .limit(3)
      .find()
  )
</script>

<template>
  <Section class="bg-white">
    <template #header>{{ prices.title }}</template>
    <div class="grid lg:grid-cols-3 gap-10">
      <article
        v-for="(item, index) in prices.items"
        :key="index"
        class="relative group"
      >
        <NuxtLink
          :to="prices.link"
          class="absolute w-full h-full z-10"
        />
        <div class="h-full flex flex-col items-start justify-between bg-white rounded-xl shadow group-hover:drop-shadow-xl px-8 py-6">
          <div class="mb-8">
            <h3 class="text-lg lg:text-2xl font-bold mb-4">
              {{ item.title }}
            </h3>
            <MDC
              :value="item.description"
              tag="article"
              class="text-base prose"
            />
          </div>
          <div class="flex flex-col gap-y-1">
            <div>
              <span class="text-2xl font-light line-through">{{ item.discount && item.discount !== '0' ? item.discount : '' }}</span>
            </div>
            <div>
              <span class="text-2xl font-bold text-primary">{{ item.value }}</span>/
              <span class="text-2xl font-light">{{ item.duration }}</span>
            </div>
            <div class="flex items-center gap-x-2 mt-6">
              <span class="text-primary">{{ prices.action }}</span>
              <NuxtIcon name="arrow-right" class="text-primary text-sm" />
            </div>
          </div>
        </div>
      </article>
    </div>
    <div class=" flex flex-col gap-y-8 mt-24">
      <div v-if="prices.description" class="mx-auto max-w-xl">
        <p class="text-base text-center">
          {{ prices.description }}
        </p>
      </div>
      <div class="w-full text-center">
        <UButton
          :to="prices.link"
          variant="solid"
          target="_blank"
          size="xl"
        >
          {{ prices.action }}
        </UButton>
      </div>
    </div>
  </Section>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const prices = toRef(props.data)
</script>

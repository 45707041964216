<template>
  <Section class="bg-white">
    <template #header>{{ contacts.title }}</template>
    <div class="flex flex-col gap-y-12">
      <a
        class="font-light text-4xl text-center"
        :href="`tel:${contacts.phone}`">
        {{ contacts.phone }}
      </a>
      <div class="text-center font-bold text-lg">
        {{ contacts.city }}
      </div>
      <nav class="flex items-center justify-center gap-x-3 py-5 lg:py-0">
        <UButton
          v-for="(social, index) in contacts.socials"
          :key="index"
          :to="social.link"
          square
          variant="soft"
          target="_blank"
        >
          <NuxtIcon :name="social.name" class="text-5xl text-white" />
        </UButton>
      </nav>
    </div>

  </Section>
</template>
<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const contacts = toRef(props.data)
</script>
